.CodeMirror-fullscreen {
    top: calc(83px + 50px);
    @media (min-width: 768px) {
        left: 16.666667%;
    }
    z-index: 98;
}

.CodeMirror-sided {
    width: calc(50% - 8.3333335%)!important
}

.editor-toolbar {
    &.fullscreen {
        top: 83px;
        left: 16.666667%;
        z-index: 99;

        &:before {
            top: 83px;
            left: 16.666667%;
        }

        &:after {
            top: 83px;
            right: 0;
        }
    }
}

.editor-preview-side {
    width: calc(50% - 8.3333335%)!important;
    top: calc(83px + 50px);
}